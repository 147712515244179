<template>
    <p>Status: <b><font color="#32cd32">We're looking for talent</font></b>.</p>
<!--  #32cd32-->
  <h3>Why us?</h3>
  <p>We're a fun and diverse community, we need people whom are seasoned (not peppers) to come help us! These positions are volunteer-based, and can be worked whenever needed.</p>

  <h3>What we're looking for!</h3>
  <p>We're seeking</p>
 <p>
   1: HTML Developers <br />
   2: CSS Developers (Site Styling)<br/>
   3: VueJS Developers <i>(Specifically RouterJS, npm and nodejs.)</i>
 </p>
  <u>Learn more</u> by visiting our <a href="https://discord.gg/UxxNp4SHF3">Discord</a>


<!--  <p><br />All work is considered voluntary, we dedicate our free-time to work on the site. No work is paid, and is withheld by I:COA.</p>-->
</template>
<script>
document.title = "I:COA | Jobs";


</script>>