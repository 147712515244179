<template>
    <h2>We're all about fast, secure, and safe content.</h2>
    <p>I:COA utilizes scalable & unique server solutions, with industry leading security-edge solutions.</p>
    <p>We host all our content on-server, inspect our material frequently and ensure all our builds are passing, secure & safe to deploy.</p>
    <hr>
    <h3>Want to know more?</h3>
    <p>Email our <a href="mailto:secops@indianacoa.com">Security Operations</a> team. </p>
    

    
</template>