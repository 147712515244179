<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">

  <div class="about">
    <h1>Culture at Indiana: Crossroads of America:</h1>

    <h2>What is Indiana: Crossroads of America?</h2>
    <p>
      Indiana: Crossroads of America is primarily a Discord community that's
      dedicated to bringing the people of Indiana together. We do this
      regardless of differences.
    </p>
    <h2>Why did we create this amazing community?</h2>
    <div class="b">
      The Indiana: Crossroads of America community was founded when its creator
      realized such a community could help bring people from the state together,
      especially in this relatively new digital era.
      </div>
  </div>


</template>
<script>
document.title = "I:COA | About";


</script>


<style>
div.b {
  word-wrap: break-word;
}

</style>